/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../helpers/useDocumentTitle";
import { useLocation, useHistory } from "react-router-dom";
import AppBar from "../components/AppBar";
import IconButton from "../components/IconButton";
import BackIcon from "../components/icons/BackIcon";
import "./NotFound.scss";

function NotFound() {
  const { t } = useTranslation();
  useDocumentTitle(t("subpageTitle", { subpageTitle: t("notFound.title") }));
  const location = useLocation();
  const history = useHistory();
  return (
    <>
      <AppBar>
        <IconButton onClick={history.goBack} title={t("common.back")}>
          <BackIcon />
        </IconButton>
        <h1 className="Title">{t("notFound.title")}</h1>
      </AppBar>
      <div className="NotFoundWrapper">
        <div className="NotFound">
          {t("notFound.text", { path: location.pathname })}
        </div>
      </div>
    </>
  );
}

export default NotFound;
