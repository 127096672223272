/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../helpers/useDocumentTitle";
import AppBar from "../components/AppBar";
import Button from "../components/Button";
import SadIcon from "../components/icons/SadIcon";
import { useHistory } from "react-router-dom";
import "./Error.scss";

function Error(props) {
  const { t } = useTranslation();
  useDocumentTitle(t("subpageTitle", { subpageTitle: t("error.title") }));

  const history = useHistory();
  const handleReload = () => {
    props.onBeforeReload();
    history.push("/");
  };

  return (
    <>
      <AppBar>
        <h1 className="Title">{t("error.title")}</h1>
      </AppBar>
      <div className="ErrorWrapper">
        <div className="Error">
          <SadIcon />
          <div className="ErrorInfo">
            <span className="Text">{t("error.text")}</span>
            <Button onClick={handleReload}>{t("error.btnReload")}</Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Error;
