import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import FrameListItem from "./FrameListItem";
import Loader from "../../../components/Loader";
import "./FrameList.scss";

function FrameList(props) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [loadedImgs, setLoadedImgs] = useState(0);
  const imgLoadHandler = () => {
    setLoadedImgs(loadedImgs + 1);
  };
  useEffect(() => {
    if (loadedImgs === props.project.frames.length) {
      setLoading(false);
    }
  }, [loadedImgs, props.project.frames.length]);

  const ref = useRef(null);
  const [autoScroll, setAutoScroll] = useState(true);
  useLayoutEffect(() => {
    ref.current.scrollLeft = 0;
  }, []);
  useEffect(() => {
    if (autoScroll && !loading) {
      ref.current.scrollLeft =
        ref.current.scrollWidth - ref.current.clientWidth;
    }
  });
  const handleScroll = () => {
    if (!loading) {
      setAutoScroll(
        ref.current.scrollLeft ===
          ref.current.scrollWidth - ref.current.clientWidth
      );
    }
  };

  const frameListItems = props.project.frames.map((frameID, frameIndex) => (
    <FrameListItem
      project={props.project}
      frameID={frameID}
      frameIndex={frameIndex}
      onImgLoad={imgLoadHandler}
      key={frameID}
    />
  ));
  return (
    <ul
      className={classNames("FrameList", { Loading: loading })}
      ref={ref}
      onScroll={handleScroll}
      aria-label={t("frameList.frames")}
    >
      {frameListItems}
      {loading && (
        <div className="FrameListLoaderOverlay">
          <Loader />
        </div>
      )}
    </ul>
  );
}

export default FrameList;
