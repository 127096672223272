import { useState } from "react";
import { useHistory } from "react-router-dom";
import store from "../../../store";

import useDocumentTitle from "../../../../helpers/useDocumentTitle";
import { useTranslation } from "react-i18next";

import AppBar from "../../../components/AppBar";
import IconButton from "../../../components/IconButton";
import { Link } from "react-router-dom";
import BackIcon from "../../../components/icons/BackIcon";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import "./ProjectSettings.scss";

function ProjectSettings(props) {
  const { t } = useTranslation();
  useDocumentTitle(
    t("subpageTitle", { subpageTitle: t("projectSettings.title") })
  );

  const history = useHistory();

  const [titleInputValue, setTitleInputValue] = useState(props.project.title);
  const handleTitleInputChange = (event) => {
    setTitleInputValue(event.target.value);
  };
  const [frameRateInputValue, setFrameRateInputValue] = useState(
    props.project.frameRate
  );
  const handleFrameRateInputChange = (event) => {
    setFrameRateInputValue(event.target.value);
  };

  const isValid = () =>
    titleInputValue.trim() !== "" &&
    Number.isInteger(Number(frameRateInputValue)) &&
    Number(frameRateInputValue) > 0;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isValid) {
      return;
    }

    try {
      await store.updateProject(props.project.id, {
        title: titleInputValue,
        frameRate: Number(frameRateInputValue),
      });
      props.onUpdateProject();
      history.push(`/project/${props.project.id}`);
    } catch (err) {
      return;
    }
  };

  return (
    <>
      <AppBar>
        <IconButton
          component={Link}
          to={`/project/${props.project.id}`}
          title={t("common.back")}
        >
          <BackIcon />
        </IconButton>
        <h1 className="Title">{t("projectSettings.title")}</h1>
      </AppBar>
      <div className="ProjectSettingsWrapper">
        <form className="ProjectSettings" onSubmit={handleSubmit}>
          <Input
            id="ProjectSettingsTitleInput"
            label={t("projectSettings.lblTitle")}
            value={titleInputValue}
            onChange={handleTitleInputChange}
            autoFocus
          />
          <Input
            id="ProjectSettingsFrameRateInput"
            label={t("projectSettings.lblFrameRate")}
            inputMode="numeric"
            value={frameRateInputValue}
            onChange={handleFrameRateInputChange}
          />
          <Button disabled={!isValid()}>{t("projectSettings.btnSave")}</Button>
        </form>
      </div>
    </>
  );
}

export default ProjectSettings;
