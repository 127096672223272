/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../helpers/useDocumentTitle";
import AppBar from "../components/AppBar";
import { Link } from "react-router-dom";
import IconButton from "../components/IconButton";
import BackIcon from "../components/icons/BackIcon";
import TextContent from "../components/TextContent";
import "./About.scss";

function About() {
  const { t } = useTranslation();
  useDocumentTitle(t("subpageTitle", { subpageTitle: t("about.title") }));
  return (
    <>
      <AppBar>
        <IconButton component={Link} to="/" title={t("common.back")}>
          <BackIcon />
        </IconButton>
        <h1 className="Title">{t("about.title")}</h1>
      </AppBar>
      <div className="AboutWrapper">
        <TextContent
          className="About"
          dangerouslySetInnerHTML={{ __html: t("about.text") }}
        />
      </div>
    </>
  );
}

export default About;
