/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

import { openDB } from "idb";
import { mutate as arrayMove } from "array-move";

class Store {
  constructor() {
    this.open();
  }

  //open connects to IndexedDB.
  open() {
    this.db = openDB("stopMotion", 1, {
      upgrade: (db) => {
        if (!db.objectStoreNames.contains("projects")) {
          db.createObjectStore("projects", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
        if (!db.objectStoreNames.contains("frames")) {
          db.createObjectStore("frames", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      },
      terminated: () => {
        this.open();
      },
    });
  }

  //getAllProjects returns a list of all projects.
  async getAllProjects() {
    return await (await this.db).getAll("projects");
  }
  //getProject returns the project with the specified ID.
  async getProject(id) {
    return await (await this.db).get("projects", id);
  }

  //createProject creates a new project and returns its ID.
  async createProject(data) {
    const id = await (await this.db).add("projects", {
      title: data.title,
      frameRate: data.frameRate,
      frames: [],
      lastUsed: new Date(),
    });
    return id;
  }
  //updateProject changes the title or the frame rate of the project with the specified ID.
  async updateProject(id, data) {
    const project = await this.getProject(id);
    if (!project) {
      throw new Error('There is no project with the ID "' + id + '"');
    }

    if (data.title) {
      project.title = data.title;
    }
    if (data.frameRate) {
      project.frameRate = data.frameRate;
    }

    await (await this.db).put("projects", project);
  }
  //touchProject updates the "last used"-timestamp of the project with the specified ID.
  async touchProject(id) {
    const project = await this.getProject(id);
    if (!project) {
      throw new Error('There is no project with the ID "' + id + '"');
    }
    project.lastUsed = new Date();
    await (await this.db).put("projects", project);
  }
  //deleteProject deletes the project with the specified ID.
  async deleteProject(id) {
    const project = await this.getProject(id);
    for (const frameID of project.frames) {
      await (await this.db).delete("frames", frameID);
    }
    await (await this.db).delete("projects", id);
  }

  //getFrame returns the frame with the specified ID.
  async getFrame(id) {
    const frame = await (await this.db).get("frames", id);
    return new Blob([frame.data], { type: frame.type });
  }
  //addFrame adds a new frame that contains the data of the specified Blob to the project with the specified ID.
  async addFrame(projectID, blob) {
    const project = await this.getProject(projectID);
    if (!project) {
      throw new Error('There is no project with the ID "' + projectID + '"');
    }
    const frameID = await (await this.db).add("frames", {
      type: blob.type,
      data: await blob.arrayBuffer(),
    });
    project.frames.push(frameID);
    await (await this.db).put("projects", project);
    return frameID;
  }
  //moveFrame moves the frame with the specified ID to a new position.
  async moveFrame(projectID, frameID, newIndex) {
    const project = await this.getProject(projectID);
    if (!project) {
      throw new Error('There is no project with the ID "' + projectID + '"');
    }
    const oldIndex = project.frames.indexOf(frameID);
    if (oldIndex === -1) {
      throw new Error(
        'The project with the ID "' +
          projectID +
          '" doesn\'t have a frame with the ID "' +
          frameID +
          '"'
      );
    }
    arrayMove(project.frames, oldIndex, newIndex);
    await (await this.db).put("projects", project);
  }
  //updateFrame changes the data contained in the frame with the specified ID to the data of the specified Blob.
  async updateFrame(id, blob) {
    await (await this.db).put("frames", {
      id,
      type: blob.type,
      data: await blob.arrayBuffer(),
    });
  }
  //deleteFrame deletes the frame with the specified ID.
  async deleteFrame(projectID, frameID) {
    const project = await this.getProject(projectID);
    if (!project) {
      throw new Error('There is no project with the ID "' + projectID + '"');
    }
    const index = project.frames.indexOf(frameID);
    if (index === -1) {
      throw new Error(
        'The project with the ID "' +
          projectID +
          '" doesn\'t have a frame with the ID "' +
          frameID +
          '"'
      );
    }
    project.frames.splice(index, 1);
    await (await this.db).put("projects", project);
    await (await this.db).delete("frames", frameID);
  }
}

if (!Blob.prototype.arrayBuffer) {
  Blob.prototype.arrayBuffer = function () {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsArrayBuffer(this);
    });
  };
}

export default new Store();

if (process.env.NODE_ENV !== "production") {
  window.store = new Store();
}
