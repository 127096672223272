import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import store from "../../../store";
import BlobImg from "../../../components/BlobImg";
import "./FrameListItem.scss";

function FrameListItem(props) {
  const { t } = useTranslation();

  const [blob, setBlob] = useState(null);
  useEffect(() => {
    (async () => {
      setBlob(await store.getFrame(props.frameID));
    })();
  }, [props.frameID]);

  return (
    <li className="FrameListItem">
      <BlobImg
        src={blob}
        onLoad={props.onImgLoad}
        onError={props.onImgLoad}
        aria-label={t("frameList.frameAtIndex", {
          index: props.frameIndex + 1,
        })}
      />
      <span className="Index" aria-hidden="true">
        {props.frameIndex + 1}
      </span>
    </li>
  );
}

export default FrameListItem;
