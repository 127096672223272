/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

import { useState, useEffect, useRef } from "react";
import store from "../../../store";

import StreamVideo from "../../../components/StreamVideo";
import Loader from "../../../components/Loader";
import IconButton from "../../../components/IconButton";
import Button from "../../../components/Button";
import CameraIcon from "../../../components/icons/CameraIcon";
import SwitchCameraIcon from "../../../components/icons/SwitchCameraIcon";
import UploadIcon from "../../../components/icons/UploadIcon";
import CameraErrorIcon from "../../../components/icons/CameraErrorIcon";

import { useTranslation } from "react-i18next";
import "./Recorder.scss";

function Recorder(props) {
  const { t } = useTranslation();

  const [stream, setStream] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [cameraID, setCameraID] = useState(null);
  const [error, setError] = useState(false);

  const getCameras = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const cameras = devices.filter((device) => device.kind === "videoinput");
    if (cameras.length === 0) {
      throw new Error("No camera was found");
    }
    return cameras;
  };

  useEffect(() => {
    (async () => {
      try {
        const cameras = await getCameras();
        setCameraID(cameras[0].deviceId);
      } catch (error) {
        setError(true);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (cameraID) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: {
              deviceId: { exact: cameraID },
              width: 999999,
              height: 999999,
            },
            audio: false,
          });
          for (const track of stream.getTracks()) {
            track.onended = () => {
              setError(true);
            };
          }
          setVideoPlaying(false);
          setStream(stream);
        } catch (error) {
          setError(true);
        }
      }
    })();
  }, [cameraID]);

  useEffect(
    () => () => {
      if (stream) {
        for (const track of stream.getTracks()) {
          track.stop();
        }
      }
    },
    [stream]
  );

  const videoRef = useRef(null);
  let ImageCapture =
    window.ImageCapture ||
    class {
      takePhoto() {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        ctx.drawImage(videoRef.current, 0, 0);
        return new Promise((resolve, reject) => {
          try {
            canvas.toBlob((blob) => resolve(blob));
          } catch (err) {
            reject(err);
          }
        });
      }
    };

  const handleTakePhoto = async () => {
    const imgCapture = new ImageCapture(stream.getVideoTracks()[0]);
    const blob = await imgCapture.takePhoto();
    await store.addFrame(props.project.id, blob);
    props.onUpdateProject();
  };

  const handleSwitchCamera = async () => {
    try {
      const cameras = await getCameras();
      if (cameras.length === 1) {
        return;
      }

      setStream(null);
      let cameraIndex = cameras.findIndex(
        (camera) => camera.deviceId === cameraID
      );
      cameraIndex++;
      if (cameraIndex >= cameras.length) {
        cameraIndex = 0;
      }
      setCameraID(cameras[cameraIndex].deviceId);
    } catch (error) {
      setError(true);
    }
  };

  const fileInputRef = useRef(null);
  const [uploadRunning, setUploadRunning] = useState(false);
  const handleUpload = () => {
    fileInputRef.current.click();
  };
  const handleSelectFiles = async () => {
    setUploadRunning(true);
    for (const file of fileInputRef.current.files) {
      await store.addFrame(props.project.id, file);
      props.onUpdateProject();
    }
    setUploadRunning(false);
  };

  const handleTryAgain = async () => {
    try {
      setStream(null);
      setError(false);

      const cameras = await getCameras();
      setCameraID(null);
      if (cameras.some((camera) => camera.deviceId === cameraID)) {
        setCameraID(cameraID);
      } else {
        setCameraID(cameras[0].deviceId);
      }
    } catch (error) {
      setError(true);
    }
  };

  const loading = !stream || !videoPlaying;

  if (error) {
    return (
      <div className="CameraErrorWrapper">
        <div className="CameraError">
          <CameraErrorIcon />
          <span className="Text">{t("recorder.cameraError")}</span>
          <Button onClick={handleTryAgain}>{t("recorder.btnTryAgain")}</Button>
        </div>
      </div>
    );
  }

  return (
    <div className="Recorder">
      <div className="ButtonPanel" />
      <StreamVideo
        className="Preview"
        src={stream}
        autoPlay
        ref={videoRef}
        onPlay={() => setVideoPlaying(true)}
        aria-label={t("recorder.liveCameraPreview")}
        tabIndex="-1"
      />
      <div className="ButtonPanel">
        <div className="ButtonPanelInner">
          <IconButton
            onClick={handleSwitchCamera}
            title={t("recorder.btnSwitchCamera")}
          >
            <SwitchCameraIcon />
          </IconButton>
          <IconButton
            onClick={handleTakePhoto}
            title={t("recorder.btnTakePhoto")}
            large
          >
            <CameraIcon />
          </IconButton>
          <IconButton
            onClick={handleUpload}
            disabled={uploadRunning}
            loading={uploadRunning}
            title={t("recorder.btnUpload")}
          >
            <UploadIcon />
          </IconButton>
          <input
            className="HiddenFileInput"
            type="file"
            multiple
            ref={fileInputRef}
            onChange={handleSelectFiles}
          />
        </div>
      </div>
      {loading && (
        <div className="LoaderOverlay">
          <Loader />
        </div>
      )}
    </div>
  );
}

export default Recorder;
