/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

import { parsePath } from "history";

//preserveQueryParams copies the query parameters whose keys are listed
//in preservedParams from oldParams to newParams, but it doesn't overwrite
//existing query parameters in newParams.
function preserveQueryParams(oldParams, newParams, preservedParams) {
  const oldQuery = new URLSearchParams(oldParams);
  let preservedQuery = new URLSearchParams();
  for (const k of preservedParams) {
    const value = oldQuery.get(k);
    if (value) {
      preservedQuery.set(k, value);
    }
  }

  let newQuery = new URLSearchParams(newParams);
  for (const [k] of preservedQuery) {
    if (!newQuery.has(k)) {
      newQuery.set(k, preservedQuery.get(k));
    }
  }

  return newQuery.toString();
}

//createPreserveQueryParamsHistory returns a wrapper around the specified
//history object that preserves the query parameters whose keys are listed
//in preservedQueryParams when a new page is loaded.
function createPreserveQueryParamsHistory(history, preservedQueryParams) {
  const oldPush = history.push;
  history.push = (path, state) => {
    if (typeof path === "string") {
      path = parsePath(path);
    }
    path.search = preserveQueryParams(
      history.location.search,
      path.search,
      preservedQueryParams
    );
    oldPush.apply(history, [path, state]);
  };

  const oldReplace = history.replace;
  history.replace = (path, state) => {
    if (typeof path === "string") {
      path = parsePath(path);
    }
    path.search = preserveQueryParams(
      history.location.search,
      path.search,
      preservedQueryParams
    );
    oldReplace.apply(history, [path, state]);
  };

  return history;
}

export default createPreserveQueryParamsHistory;
