/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

import { useState } from "react";
import "./translations/translations";

import "./App.scss";
import "focus-visible";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";

import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import createPreserveQueryParamsHistory from "../helpers/PreserveQueryParamsHistory";

import Home from "./views/Home/Home";
import Project from "./views/Project/Project";
import About from "./views/About";
import NotFound from "./views/NotFound";

import ErrorBoundary from "../helpers/ErrorBoundary";
import Error from "./views/Error";

const history = createPreserveQueryParamsHistory(createBrowserHistory(), [
  "lang",
]);

function App() {
  const [hasError, setHasError] = useState(false);
  const handleError = () => {
    setHasError(true);
  };
  const handleBeforeReload = () => {
    setHasError(false);
  };

  return (
    <ErrorBoundary onError={handleError}>
      <Router history={history}>
        <div className="App">
          {!hasError ? (
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/project/:id">
                <Project />
              </Route>
              <Route path="/about" exact>
                <About />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          ) : (
            <Error onBeforeReload={handleBeforeReload} />
          )}
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
