/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import store from "../../store";
import ProjectListItem from "./ProjectListItem";
import Loader from "../../components/Loader";
import AddIcon from "../../components/icons/AddIcon";
import "./ProjectList.scss";

function ProjectList() {
  const { t } = useTranslation();

  const [projects, setProjects] = useState(null);
  useEffect(() => {
    (async () => {
      let projects;
      try {
        projects = await store.getAllProjects();
      } catch (err) {
        return;
      }
      projects.sort((a, b) => b.lastUsed - a.lastUsed);
      setProjects(projects);
    })();
  }, []);

  const [loadedImgs, setLoadedImgs] = useState(0);
  const imgLoadHandler = () => {
    setLoadedImgs((loadedImgs) => loadedImgs + 1);
  };

  const loading =
    !projects ||
    loadedImgs < projects.filter((project) => project.frames.length > 0).length;

  const history = useHistory();
  const handleNewProject = async () => {
    try {
      const id = await store.createProject({
        title: t("home.untitled"),
        frameRate: 15,
      });
      history.push(`/project/${id}`);
    } catch (err) {
      return;
    }
  };

  return (
    <div className={classNames("ProjectListWrapper", { Loading: loading })}>
      <ul className="ProjectList" aria-label={t("home.projects")}>
        {projects &&
          projects.map((project) => (
            <ProjectListItem
              project={project}
              key={project.id}
              onImgLoad={imgLoadHandler}
            />
          ))}
        <li>
          <button className="ProjectListItem" onClick={handleNewProject}>
            <div className="PreviewImageWrapper">
              <div className="PreviewImageSVGWrapper">
                <AddIcon />
              </div>
            </div>
            <span className="Title">{t("home.newProject")}</span>
          </button>
        </li>
      </ul>
      {loading && (
        <div className="ProjectListLoaderOverlay">
          <Loader />
        </div>
      )}
    </div>
  );
}

export default ProjectList;
