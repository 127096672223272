/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import store from "../../store";
import BlobImg from "../../components/BlobImg";
import ImageIcon from "../../components/icons/ImageIcon";
import "./ProjectListItem.scss";

function ProjectListItem(props) {
  const [previewFrame, setPreviewFrame] = useState(null);
  useEffect(() => {
    (async () => {
      if (props.project.frames.length === 0) {
        return;
      }
      const previewFrameID =
        props.project.frames[Math.floor(props.project.frames.length / 2)];
      setPreviewFrame(await store.getFrame(previewFrameID));
    })();
  }, [props.project.frames]);

  return (
    <li>
      <Link className="ProjectListItem" to={"/project/" + props.project.id}>
        <div className="PreviewImageWrapper">
          {props.project.frames.length > 0 ? (
            <BlobImg
              className="PreviewImage"
              src={previewFrame}
              onLoad={props.onImgLoad}
              onError={props.onImgLoad}
              aria-hidden="true"
            />
          ) : (
            <div className="PreviewImageSVGWrapper">
              <ImageIcon />
            </div>
          )}
        </div>
        <span className="Title">{props.project.title}</span>
      </Link>
    </li>
  );
}

export default ProjectListItem;
