import { useState, useEffect } from "react";
import { useParams, Switch, Route } from "react-router-dom";
import store from "../../store";

import ProjectEditor from "./ProjectEditor/ProjectEditor";
import ProjectSettings from "./ProjectSettings/ProjectSettings";
import NotFound from "../NotFound";

import { useTranslation } from "react-i18next";
import AppBar from "../../components/AppBar";
import IconButton from "../../components/IconButton";
import { Link } from "react-router-dom";
import BackIcon from "../../components/icons/BackIcon";
import Loader from "../../components/Loader";
import "./Project.scss";

function Project() {
  const { t } = useTranslation();
  const params = useParams();

  const [project, setProject] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    (async () => {
      if (!/^\d+$/.test(params.id)) {
        setNotFound(true);
        return;
      }

      try {
        const project = await store.getProject(Number(params.id));
        if (project) {
          setProject(project);
          await store.touchProject(project.id);
        } else {
          setNotFound(true);
        }
      } catch (err) {
        return;
      }
    })();
  }, [params.id]);

  const handleUpdateProject = async () => {
    try {
      setProject(await store.getProject(project.id));
    } catch (err) {
      return;
    }
  };

  if (notFound) {
    return <NotFound />;
  }

  if (!project) {
    return (
      <>
        <AppBar>
          <IconButton component={Link} to="/" title={t("common.back")}>
            <BackIcon />
          </IconButton>
        </AppBar>
        <div className="ProjectLoaderWrapper">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <Switch>
      <Route path="/project/:id" exact>
        <ProjectEditor
          project={project}
          onUpdateProject={handleUpdateProject}
        />
      </Route>
      <Route path="/project/:id/settings" exact>
        <ProjectSettings
          project={project}
          onUpdateProject={handleUpdateProject}
        />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default Project;
