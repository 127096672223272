/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

import { Component } from "react";

class ErrorBoundary extends Component {
  //componentDidCatch is not available as a hook, so I had to write this class component :-(
  componentDidCatch(error, errorInfo) {
    this.props.onError(error, errorInfo);
  }
  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
